.section-container {
  /* width: 100%; */
  /* overflow: auto; */
  overflow-x: auto;
  flex: 1;
}

/* body {
  overflow: hidden;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 3vmin;
  pointer-events: none;
}

.maintenance-app-logo {
  height: 5vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.fav-color {
  color: #ffb034;
}
.fav-color:hover {
  cursor: pointer;
}

.width-95 {
  width: 95%;
}

.text-icon-fav {
  font-size: 13px;
  margin-right: 0.2rem;
  position: relative;
  top: 1px;
  left: 5px;
}

.d-block {
  display: block;
}

.text-icon-attach {
  font-size: 14px;
  margin-right: 0.2rem;
  position: relative;
  top: 0.2rem;
}

.input-code-key {
  width: 300px;
  text-align: center;
}

.mt-for-btn-add {
  margin-top: 42px;
}

.legend-responsible-btn {
  position: absolute;
  margin-top: 8px;
  margin-left: 8px;
}

.pb-30px {
  padding-bottom: 30px;
}

.max-width-50 {
  max-width: 50%;
}

.App-link {
  color: #61dafb;
}

.notification-alert-text {
  letter-spacing: -1px;
}

.label-filter {
  color: #aaa !important;
  font-size: 12px !important;
  font-weight: 500 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ml-for-error-msg-out-of-range {
  margin-left: 8px;
}

.separator-activity {
  border-top: 1px solid #fafafa;
}
.child-dropzone {
  min-height: 100px;
  border: 1px black solid;
  border-style: dashed;
}

.view-wrapper-title {
  margin-top: 0.2rem;
}

.view-wrapper-command-bar-wrapper {
  min-height: 3rem;
}

.fw-400 {
  font-weight: 400;
}

.mt-2-imp {
  margin-top: 15px !important;
}
.mt-2 {
  margin-top: 15px;
}

.mt-5 {
  margin-top: 35px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-4 {
  margin-top: 25px;
}

.mb-2 {
  margin-bottom: 15px;
}

.text-center {
  text-align: center;
}

@media (639px <= width <= 1365px) {
  .iconized-menu {
    max-width: 35px;
    height: 100%;
  }
}

/* .fc-toolbar-title {
 display: none;
} */

.ms-Dialog-title {
  background-color: rgb(32, 131, 217) !important;
  color: white !important;
  margin-bottom: 15px;
}

.float-right {
  float: right !important;
}

.dropzone {
  overflow-y: auto;
}

.qr-size {
  height: 90px;
  width: 90px;
  background: #d7d7d7;
  margin: auto;
}

.android-logo {
  margin: auto;
  height: 90px;
  width: 90px;
}

.ios-logo {
  margin: auto;
  height: 70px;
  width: 70px;
}

.btn-dinamic-table {
  z-index: 10;
  position: absolute;
  top: 10px;
  right: 231px;
}

.dropzone-drag-active,
.dropzone-drag-accept {
  border: 2px dashed #979393;
  box-sizing: border-box;
}

.ms-Dialog-main {
  transition: max-height 1s ease-in;
  transition: max-height 1s ease-out;
}

.text-bold {
  font-weight: 500;
}

@media (width <= 1023px) {
  .title-filter-wrapper {
    margin-top: 15px;
  }
  .btn-filter-wrapper {
    margin-top: 29px;
  }
  .float-right-media {
    float: right;
  }
}

@media (width > 1023px) {
  .filter-menu {
    border-right: 1px solid rgb(238, 238, 238);
  }
}

@media (width <= 1023px) {
  .clean-filters-calendar {
    /* margin-top: 20px; */
    padding-top: 35px;
  }
}

.chip-filter {
  background-color: rgb(0, 120, 212);
  border-radius: 10px;
  width: fit-content;
  font-size: 12px;
  padding: 2px 8px 3px 8px;
  display: inline-block;
  margin-left: 10px;
  font-weight: 500;
  cursor: pointer;
  color: white;
}
.chip-filter-label {
  cursor: pointer;
}

.label-filter-list {
  color: red;
}

.filter-textfield-list .ms-TextField-fieldGroup {
  border: 1px solid #aaa !important;
}

.filter-textfield-list label {
  color: #aaa;
  font-size: 12px;
  padding: 3px 0px !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px; /* height of horizontal scrollbar ← You're missing this */
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
}

.icon-external-calendar {
  font-weight: 500;
  color: #000;
  margin-right: 4px;
}

.panel {
  margin-top: 50px;
  margin-bottom: 21px;
  background-color: #f9f9f9;
  border-radius: 2px;
  webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}

.panel-heading {
  color: #ffffff;
  /* background-color: rgb(0, 120, 212) !important; */
  border-bottom: 1px solid #909fa72f;
  height: 70px;
  text-align: center;
  display: flex;
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
}

.panel-footer {
  color: #ffffff;
  /* background-color: rgb(0, 120, 212) !important; */
  border-top: 1px solid #909fa72f;
  height: 70px;
  text-align: center;
  display: flex !important;
  border-radius: 2px;
  flex-direction: column;
  justify-content: center;
}

.panel-pill {
  padding: 25px 25px !important;
  overflow: hidden;
  display: table-cell;
  table-layout: fixed;
  vertical-align: middle;
  float: none !important;
  border-radius: 4px;
}

.login-button {
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 120, 212, 0.8);
}

.login-button:disabled,
.login-button[disabled] {
  color: #909fa7;
  background-color: #f9f9f9;
}

.remember-user-checkbox {
  display: inline-block;
  margin-bottom: 30px;
}

.d-inline {
  display: inline;
}

.ml-for-cancel-selection {
  margin-left: 50px;
}

.d-inline-block {
  display: inline-block;
}

.forgot-password {
  color: #909fa7;
}

.default {
  opacity: 0;
  visibility: hidden;
  top: -10px;
  transform: scaleY(0);
  max-height: 0;
}

.show {
  opacity: 1;
  visibility: visible;
  top: 0px;
  transform: scaleY(1);
  transform-origin: top;
  max-height: 400px;
  transition: opacity 0.3s 0.3s, top 0.3s 0.3s, visibility 0s 0s, transform 0.3s 0.3s, max-height 0.3s 0.3s;
}

.login-input-field {
  margin-bottom: 15px;
}

.iniciar-sesion-btn {
  width: 100%;
  height: 40px;
  margin-bottom: 15px !important;
}

.wrapper-login {
  width: 70%;
  margin: auto;
}

.form-back {
  background: #ebebeb;
  min-height: 100%;
}

.tts-logo {
  /* width: 85%; */
  margin: 0 auto;
}

.project-title {
  font-weight: 500 !important;
  margin-top: 10px;
}

.icon-project-title {
  font-weight: 500 !important;
  margin: 0rem 0.5rem;
}

.margin-project-title {
  margin: 0rem 0.5rem;
}

.text-principal {
  padding: 0rem 7rem !important;
}

.pagination-dropdown-container {
  float: left;
  display: flex;
  justify-content: center;
}

.color-success {
  color: rgb(16, 124, 16);
}
.color-error {
  color: rgb(168, 0, 0);
}

.color-inactive {
  color: rgb(168, 0, 0);
}
.color-initial {
  color: rgb(121, 121, 121);
}
.color-inProcess {
  color: rgb(255 195 55);
}
.color-finished {
  color: rgb(16, 124, 16);
}

.config-label {
  color: #767676;
}

.cursor-pointer {
  cursor: "pointer";
}

/* g > .tick > text {
  fill: transparent !important;
} */

.xAxis-354 > g > text {
  fill: transparent !important;
}

.widget-pill {
  padding: 0 10px !important;
}

.widget-pill-detail {
  background-color: rgb(6, 66, 145);
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.widget-pill-title {
  color: white;
  font-weight: bold;
  font-size: 1.5em !important;
}

.widget-pill-subtitle {
  color: white;
  font-size: 1em !important;
}

.bg-blue-primary {
  background: rgb(0, 120, 212);
}

.bg-blue-secondary {
  background: rgb(113, 175, 229);
}

.bg-gray {
  background: #cbcbcbc4;
}

.bg-white {
  background: white;
  font-weight: 500;
}

.pill {
  min-height: 75px;
  border-radius: 2px;
  /* max-width: 250px; */
  margin: auto;
  /* max-width: 200px; */
}

.pill-left {
  min-height: 80px;
  border-radius: 2px;
  background-color: #2196f3;
  /* max-width: 200px; */
}

.br-2 {
  border-radius: 2px;
}

.mt-2-for-pill {
  margin-top: 15px;
}
.mt-3-for-pill {
}
.highlight-pill {
  margin-top: 5px;
  height: 30px;
}

.p-0 {
  padding: 0;
}

.padding-for-container {
  padding: 5px 10px !important;
  border-left: 1px solid #e1e1e17d;
  border-right: 1px solid #e1e1e17d;
}

.mt-for-pill {
  margin-top: 20px;
}

.mt-for-small-pill {
  margin-top: 25px;
}

.fs-for-pill-left {
  font-size: 25px;
}

.fs-for-small-pill-left {
  font-size: 20px;
}

.fs-for-small-pill {
  font-size: 14px;
}

.fs-small {
  font-size: 14px !important;
}

.icon-small-pill {
  font-size: 25px;
  margin: "auto";
}

.pill-right-up-text {
  text-align: initial;
  margin-left: 10px;
  margin-top: 15px;
}

.pill-right-down-text {
  text-align: initial;
  margin-left: 10px;
  margin-top: 10px;
}

.pill-right-center-text {
  margin-top: 26px;
  margin-left: 10px;
  text-align: initial;
}

.mr-0 {
  margin-right: 0px;
}

.mr-2 {
  margin-right: 15px;
}

.mt-for-pill-container {
  margin-top: 20px;
}

.right-pill-icon {
  margin-top: 25px;
}

.mt-last-days {
  margin-top: 10px;
}

@media (width > 1023px) {
  .column-to-fix {
    width: 1.333333%;
  }

  .column-to-fix-manag {
    width: 73.666667%;
  }
}

.mt-2-in-down-xl {
  margin-top: 2px;
}

.mt-2-in-down-xxl {
  margin-top: 2px;
}

@media (width <= 1365px) {
  .mt-2-in-down-xxl {
    margin-top: 15px;
  }
}

.padding-custom-new-doc {
  padding: 8px 25px 0px 25px;
}

.float-right-in-xxl {
}
@media (width > 1365px) {
  .float-right-in-xxl {
    float: right;
  }
}

.text-error {
  color: red;
}

.ml-3 {
  margin-left: 27px;
}

.btn-custom-h {
  height: 10px !important;
}

.px-0-in-down-xl {
}

.ta-end {
  text-align: end;
}

.mt-for-btn-search {
  margin-top: 29px;
}

@media (width <= 1023px) {
  .px-0-in-down-xl {
    padding: 0px 0px;
  }

  .mt-2-in-down-xl {
    margin-top: 15px;
  }

  .width-in-down-xl {
    width: auto;
  }
  .padding-for-container {
    padding: 5px 10px !important;
    border-left: none;
    border-right: none;
  }

  .px-0-in-down-xl {
    padding-left: 0px;
    padding-left: 0px;
  }
}

.max-width-for-pill {
  max-width: 250px;
}

.padding-for-panel {
  margin: 5px 0px;
  padding: 0.7rem 0px;
  background-color: #f0f0f0;
}

.padding-for-panel-project {
  margin: 5px 0px;
}
.d-inline-table {
  display: inline-table;
}
.ml-2 {
  margin-left: 15px;
}
.last-days {
  font-weight: 600;
  /* margin-top: 10px; */
}

.fs-for-chart {
  font-size: 12px;
  position: absolute;
  left: 41px;
  top: -100px;
  font-weight: 400;
  color: #489cdc;
}

.fs-for-minipanel-chart {
  font-size: 12px;
  font-weight: 400;
  color: #489cdc;
}

.color-activities {
}
.color-docs {
  color: #489cdc;
}
.bg-transparent {
  background-color: transparent;
}

.text-icon-for-pill {
  font-size: 19px;
  margin-right: 0rem;
  position: relative;
  top: 0.2rem;
}

.opacity-half {
  opacity: 0.7;
}

.wizard-btn {
  float: right;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.ml-10px {
  margin-left: 10px;
}

.ml-1 {
  margin-left: 5px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #d9d9d9;
  margin: 1em 0;
  padding: 0;
}

.body-bg {
  background-color: rgb(250, 249, 248);
}

.menu-basic-view-btn {
  height: 24px;
}

.fw-500 {
  font-weight: 500;
}

.fw-300 {
  font-weight: 300;
}

.fw-700 {
  font-weight: 700;
}

@media (width > 1365px) {
  .same-height-row-parent {
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .col-same-height {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.mr-5 {
  margin-right: 25px;
}

.mr-1rem {
  margin-right: 1rem;
}
.mr-1 {
  margin-right: 5px;
}

#block-dialog-id {
  background-color: transparent !important;
  padding: 10px;
}

.title-third-panel {
  font-size: 16px;
  font-weight: 500;
}
.mb-for-third-panel {
  margin-bottom: 40px;
}

.mt-2px {
  margin-top: 2px;
}

.warning-color {
  color: #ffc107;
}

.error-color {
  color: #ff0000 !important;
}

.success-color {
  color: rgb(40, 204, 37);
}

.link-styles {
  text-decoration: none;
  color: rgb(0, 120, 212);
}

.link-styles:hover {
  text-decoration: underline;
  cursor: pointer;
}
/*
.ms-Button:has(.ms-Button-flexContainer):has(.pivotItem) {
  background-color: #f8f8f8;
} */

td:has(.no-padding-td-df) {
  padding: 2px !important;
}

.iconized-menu::-webkit-scrollbar {
  width: 2px; /* Ancho de la barra de desplazamiento */
}

.detailview-height::-webkit-scrollbar {
  width: 4px; /* Ancho de la barra de desplazamiento */
}

.ml-8px {
  margin-left: 8px;
}

@keyframes myfirst {
  0% {
    left: 0px;
    top: 0px;
  }
  25% {
    left: 5px;
    top: 0px;
  }
  50% {
    left: 5px;
    top: 5px;
  }
  75% {
    left: 0px;
    top: 5px;
  }
}

.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 15px;
}

.file-upload-info {
  width: 350px;
  position: absolute;
  bottom: 3em;
  right: 4em;
  background-color: white;
  border-radius: 4px;
  border: 1px solid rgb(61 151 227);
  padding: 0.5em;
  z-index: 1000;
}

.file-upload-details-header {
  margin: 1rem 0;
}

.file-upload-details {
  margin: 1.5rem 0;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0.5em;
}

.iconForTeam {
  color: gray !important;
}

.iconForTeamSelected {
  color: #ff5209 !important;
}

.d-grid {
  display: grid;
}

.dialog-for-pending-tag .ms-Dialog-inner {
  padding: 0;
}

.hide-project-btn {
  display: none;
}

.tutorial-video {
  transition: 0.3s;
}

.tutorial-video:hover {
  background-color: rgb(63, 139, 255);
  opacity: 0.75;
  transition: 0.3s;
}

.tutorial-video-box {
  margin-bottom: 30px;
}

.tutorial-text-header {
  padding: 0 15px;
  text-align: center;
}

.maintenance-container {
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

[class^="xAxis"] .tick text {
  display: none;
}

.xAxisVisible [class^="xAxis"] .tick text {
  display: block !important;
  /* background-color: red; */
}

.calendar-activity-scroll {
  overflow-y: scroll;
  max-height: 550px;
}

.activity-checker-cancel-option:hover {
  text-decoration: underline;
}

.activities-list-header {
  margin-left: 600px;
  font-size: 14px;
}

.two-line-header-list div .ms-DetailsList-headerWrapper .ms-DetailsHeader .ms-DetailsHeader-cell {
  text-overflow: clip;
  line-height: normal;
}

.two-line-header-list div .ms-DetailsList-headerWrapper .ms-DetailsHeader .ms-DetailsHeader-cellTitle {
  height: 100%;
  align-items: center;
}

.tutorial-video {
  width: auto;
  /* height: 15vmin; */
  margin-top: 5px;
  height: 13em;
}

.only-tutorial-video {
  width: auto;
  height: 60vmin;
  margin-top: 5px;
  max-width: 100%;
}

.m-auto {
  margin: auto;
}

.blue {
  color: rgb(0, 120, 212);
}

.pointer {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.collab-logo {
  height: 2vmin;
}

.nav-container div {
  height: 100%;
}

.copyright-logo {
  height: 0.75vmin;
  pointer-events: none;
}

.collab-info {
  border-right: 1px solid rgb(238, 238, 238);
  padding: 10px 0;
}

.collab-info-title {
  border-right: 1px solid rgb(238, 238, 238);
}

.copyright-info {
  border-top: 1px solid rgb(238, 238, 238);
  border-right: 1px solid rgb(238, 238, 238);
  padding: 10px 0;
}

.version-info {
  border-right: 1px solid rgb(238, 238, 238);
  padding-bottom: 10px;
}

@media (width <= 1365px) {
  .hide-sidebar-info {
    display: none;
  }

  .collab-info {
    display: none;
  }
}

@media (width <= 639px) {
  .version-info {
    display: none;
  }
}

.stretch {
  display: flex;
  align-items: stretch;
}

.stretch > .stretch-column {
  display: flex;
  flex-direction: column;
}

.align-bottom {
  margin-top: auto;
}

/* ------------- ESTILOS DE TABLA */

.dx-widget {
  display: block;
  -ms-content-zooming: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  padding: 0;
  outline: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  color: #333;
  font-weight: 400;
  font-size: 13px !important;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont,
    Roboto, "Helvetica Neue", sans-serif !important;
  line-height: 1.35715;
}

.dx-datagrid-summary-item {
  font-weight: 500 !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 4s infinite;
  -moz-animation: flickerAnimation 4s infinite;
  -o-animation: flickerAnimation 4s infinite;
  animation: flickerAnimation 4s infinite;
}

.tutorial-item-title {
  font-weight: 500;
  font-size: 12px;
  /* position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%); */
}

/* .carousel-item {
  position: relative;
} */

.carousel-img {
  margin-top: 15px;
}

.carousel-img {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}

.carousel-img:hover {
  filter: brightness(90%);
  -webkit-filter: brightness(90%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.rectangulo-faltantes {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #db756e;
  border: 1px solid #db756e;
}

.rectangulo-excedidas {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #605e5c;
  border: 1px solid #605e5c;
}

.rectangulo-cargadas {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #00bcf2;
  border: 1px solid #00bcf2;
}

.rectangulo-licencia {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #b4a0ff;
  border: 1px solid #b4a0ff;
}

.detailview-height {
  height: 75vh;
}
@media (width <= 1023px) {
  .detailview-height {
    height: 66vh;
  }
}

@media (height <= 910px) {
  .detailview-height {
    height: 66vh;
  }
}

.horizontal-bar-chart.excess-hours div div div svg g rect:nth-child(2) {
  fill: rgb(0, 120, 212) !important;
}

.bg-alert-primary {
  background: rgb(231, 88, 78) !important;
}

.bg-alert-secondary {
  background: rgb(219, 117, 110) !important;
}

.mini-text {
  font-size: 10px;
}

.mmi-form {
  border-right: 1px solid rgb(238, 238, 238);
}

.width-for-ds-massive {
  width: 100px;
  display: inline-block;
  margin-right: 20px;
}

.text-icon-for-report-docs {
  font-size: 15px;
  margin-right: 0.1rem;
  position: relative;
  top: 0.2rem;
}
.adjust-label-docs-report {
  position: relative;
  top: -2px;
}

.pill-fluent {
  background: white;
  border-top: 4px solid #0078d4;
  border-radius: 2px;
  overflow: auto;
  padding-bottom: 10px;
  text-align: initial;
  max-width: 350px;
  margin: 0 auto;
}

.alert-pill-fluent {
  background: white;
  border-top: 4px solid #e7584e;
  border-radius: 2px;
  overflow: auto;
  padding-bottom: 10px;
  text-align: initial;
  max-width: 350px;
  margin: 0 auto;
}

.pill-fluent-title {
  padding-top: 0px;
  padding-bottom: 5px;
}

.pill-fluent-small {
  border-top: 4px solid #0078d4;
  border-radius: 2px;
  overflow: auto;
  padding-bottom: 5px;
  text-align: initial;
}

.bg-hat-pill {
  background-color: #0078d4;
}

.pill-fluent-title-small {
  margin-top: 0px;
  padding-bottom: 5px;
}

.text-icon-small {
  font-size: 15px;
  margin-right: 0.2rem;
  position: relative;
  top: 0.2rem;
}

/* .text-icon-small {
  font-size: 15px;
  margin-right: 0.5rem;
  position: relative;
  top: 0.2rem;
} */

.text-icon-title-small {
  font-size: 15px;
  position: relative;
  top: 0.2rem;
}

.text-icon-title-big {
  font-size: 19px;
  position: relative;
  /* top: 0.25rem; */
}

.white-color {
  color: white;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.dx-datagrid-borders > .dx-datagrid-header-panel {
  margin-right: 10px;
  margin-top: 10px;
}

.detailview-height div div div div div div div div div div .ms-DetailsRow-fields .ms-DetailsRow-cell {
  padding-top: 11px !important;
  padding-bottom: 0px !important;
  padding-right: 15px !important;
  min-height: 10px !important;
}

/* .ms-DetailsHeader {
  position: fixed !important;
  z-index: 100 !important;
  margin-top: 0px !important;
}
*/

.project-mini-panel-chart-container {
  height: 100px;
}

.smooth-label {
  color: #838383;
}

.month-box {
  max-width: 80%;
  margin: auto;
  min-height: 120px;
}

.icon-month-box {
  font-size: 50px;
  font-weight: 600;
}

.padding-month-label {
  padding-top: 9px;
}

.icon-selector-year {
  position: relative;
  top: 4px;
  color: #a7a7a7;
}

.icon-selector-year:hover {
  color: #000;
}

.icon-info-months {
  position: relative;
  top: 4px;
}

.icon-info-color {
  color: #4f6bed;
}

.icon-warning-months {
  position: relative;
  top: 2px;
}

.disabled-feature-wrapper {
  background-color: #d5d5d5;
  opacity: 0.7;
}

.disabled-feature-icon {
  position: absolute;
  z-index: 90;
  margin: auto;
  margin-top: 17%;
  top: 11%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
}

.calendar-pill-left {
  min-height: 120px;
  border-radius: 2px;
  /* max-width: 200px; */
}

.calendar-pill-unblocked {
  background-color: rgb(77, 180, 77);
}

.calendar-pill-blocked {
  background-color: rgb(182, 59, 59);
}

.mt-for-calendar-pill {
  margin-top: 40px;
}

.icon-for-calendar-pill {
  margin-top: 20px;
}

.calendar-pill-right {
  min-height: 120px;
  border-radius: 2px;
}

.d-none {
  display: none;
}

.pill-fluent-item-container {
  margin-top: 8px;
}

.pill-fluent-item-container:last-child {
  margin-bottom: 8px;
}

.text-icon-for-dialog {
  font-size: 100px;
  margin-top: 20px;
}

.box-non-has-docs-required {
  min-height: 150px;
}

.logo-home {
  position: relative;
  top: 8px;
  left: 10px;
}

.display-inline {
  display: inline !important;
}

/* Define la animación llamada 'blinking' */
@keyframes blinking {
  0% {
    opacity: 0.5; /* Empieza con poca opacidad */
  }
  50% {
    opacity: 1; /* Aumenta la opacidad a la mitad de la animación */
  }
  100% {
    opacity: 0.5; /* Vuelve a disminuir la opacidad al final de la animación */
  }
}

/* Aplica la animación 'blinking' al icono */
.blinking-icon {
  animation: blinking 1s infinite; /* Hace que la animación sea infinita y dure 1 segundo */
}
